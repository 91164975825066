import { ref } from '@vue/composition-api'
import store from '@/store'

export const useShopFiltersSortingAndPagination = () => {
    const filters = ref({
        page: 1,
        pagi: 10,
        sortBy: "created_at",
        sortDir: 'desc',
        locale: 'en',
        q: '',
    })

    const filterOptions = {
        priceRangeDefined: [
            { text: 'All', value: 'all' },
            { text: '<= $10', value: '<=10' },
            { text: '$10 - $100', value: '10-100' },
            { text: '$100 - $500', value: '100-500' },
            { text: '>= $500', value: '>=500' },
        ],


        brands: ['Insignia™', 'Samsung', 'Metra', 'HP', 'Apple', 'GE', 'Sony', 'Incipio', 'KitchenAid', 'Whirlpool'],
        ratings: [
            { rating: 4, count: 160 },
            { rating: 3, count: 176 },
            { rating: 2, count: 291 },
            { rating: 1, count: 190 },
        ],
    }

    // Sorting
    const sortBy = ref({ text: 'Sort by', value: 'id' })
    const sortByOptions = [
            { text: 'ID', value: 'id' },
            { text: 'Name', value: 'name' },

        ]
        // Sorting
    const sortDir = ref({ text: 'Sort Direction', value: 'asc' })
    const sortDirOptions = [
        { text: 'Asc', value: 'asc' },
        { text: 'Desc', value: 'desc' },

    ]

    return {
        // Filter
        filters,
        filterOptions,

        // Sort
        sortBy,
        sortByOptions,
        sortDir,
        sortDirOptions,
    }
}

export const useShopUi = () => {
    const itemView = 'grid-view'
    const itemViewOptions = [
        { icon: 'GridIcon', value: 'grid-view' },
        { icon: 'ListIcon', value: 'list-view' },
    ]

    // Pagination count <= required by pagination component
    const totalProducts = ref(null)

    return {
        itemView,
        itemViewOptions,
        totalProducts,
    }
}

export const useShopRemoteData = () => {
    const products = ref([])
    const fetchProducts = (...args) => store.dispatch('New-commerce-store/fetchNewProducts', ...args)

    return {
        products,
        fetchProducts,
    }
}